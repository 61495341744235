import * as React from "react";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";

/**
 * <a href> Link Component that is trackable using FlexClickTracker
 */
class Link extends React.Component<any, any> {
  public render() {
    const { setref, children } = this.props;

    return (
      <a ref={setref} {...this.props}>
        {children}
      </a>
    );
  }
}

const TrackedLink = FlexClickTracker(Link);

export { TrackedLink };

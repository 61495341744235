import { ClientLogger } from "bernie-client";
import { FAILED_ANALYTICS, TRACK_EVENT } from "config/systemEvents";

const LOGGER = ClientLogger.getLoggerWithIdentifier("FlexAnalyticsUtils");
const TIMEOUT = 500;

/**
 * https://confluence.expedia.biz/pages/viewpage.action?spaceKey=DAPS&title=EG+Clickstream+Event+Principles#EGClickstreamEventPrinciples-ActionsbyEventType
 */

export enum PageViewEvent {
  SERVED = "served",
  VIEWED = "viewed",
}

export enum InteractionEvent {
  SUBMITTED = "submitted",
  SELECTED = "selected",
  INPUTTED = "inputted",
  OPENED = "opened",
  CLOSED = "closed",
  INITIATED = "initiated",
  CREATED = "created",
  DELETED = "deleted",
  ADDED = "added",
  REMOVED = "removed",
}

export enum ImpressionEvent {
  PRESENTED = "presented",
  HIDDEN = "hidden",
}

export enum OutcomeEvent {
  SUBMITTED = "submitted",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

// Generic interface to ensure consistent Blossom-Flex tracking info when sending a track event
export interface EGClickStreamTrackingInfo {
  moduleName: string | null;
  action: PageViewEvent | InteractionEvent | ImpressionEvent | OutcomeEvent | null;
  rfrr?: string;
  linkName?: string;
}

/**
 * Builds an appropriate and consistent rfrr id that follows the Blossom-Flex standards
 * @param {EGClickStreamTrackingInfo} trackingInfo
 * @returns {string}
 */
export const buildFlexRfrrId = (trackingInfo: EGClickStreamTrackingInfo): string => {
  if (!trackingInfo?.action || !trackingInfo.moduleName) {
    return "unknown";
  }

  const { rfrr, moduleName, action } = trackingInfo;

  return rfrr ? `${rfrr}.${action}` : `${moduleName}.${action}`;
};

/**
 * Generic function to send a track event leveraging the new @shared-ui/clickstream-analytics-context
 * With this function, we no longer need to prop drill AnalyticsStore.
 * @param useClickTracker: a reference of useClickTracker() from @shared-ui/clickstream-analytics-context
 * @param trackingInfo
 * @param delay
 */
const sendClickstreamTrackEvent = (
  useClickTracker: (rfrr: string, linkName: string, delay?: boolean) => void,
  trackingInfo: EGClickStreamTrackingInfo,
  delay: boolean
): void => {
  if (typeof window === "undefined" || !useClickTracker) {
    return;
  }

  const rfrrId = getRfrrIdAndLogToSplunk(trackingInfo, delay);

  if (!rfrrId) {
    return;
  }

  useClickTracker(rfrrId, trackingInfo.linkName || "RFRR Action Event", delay);
};

/**
 * Sends a track event immediately. Use this when clicking through to another page.
 */
export const sendImmediateClickstreamTrackEvent = (
  trackingInfo: EGClickStreamTrackingInfo,
  useClickTracker: (rfrr: string, linkName: string, delay?: boolean) => void
) => {
  sendClickstreamTrackEvent(useClickTracker, trackingInfo, false);
};

/**
 * Sends a track event after a small delay to optimize for UI thread.
 */
export const sendDelayedClickstreamTrackEvent = (
  trackingInfo: EGClickStreamTrackingInfo,
  useClickTracker: (rfrr: string, linkName: string, delay?: boolean) => void
) => {
  sendClickstreamTrackEvent(useClickTracker, trackingInfo, true);
};

/**
 * Generic logging function to log a track event to Splunk
 * @param {EGClickStreamTrackingInfo} trackingInfo
 */
export const logTrackEventToSplunk = (trackingInfo: EGClickStreamTrackingInfo) => {
  LOGGER.logEvent(
    TRACK_EVENT,
    `Received track event: ${Object.keys(trackingInfo)
      .map((key) => `${key}="${trackingInfo[key as keyof EGClickStreamTrackingInfo]}"`)
      .join(" ")}`
  );
};

const getRfrrIdAndLogToSplunk = (trackingInfo: EGClickStreamTrackingInfo, trackImmediate: boolean) => {
  const rfrrId = buildFlexRfrrId(trackingInfo);

  if (!rfrrId) {
    LOGGER.logEvent(FAILED_ANALYTICS, "Missing moduleName or action to send track event", trackingInfo);

    return null;
  }

  // free up the UI thread so it can move on to the intended click task
  if (trackImmediate) {
    logTrackEventToSplunk(trackingInfo);
  } else {
    setTimeout(() => {
      logTrackEventToSplunk(trackingInfo);
    }, TIMEOUT);
  }

  return rfrrId;
};

import * as React from "react";
import { ClickTracker } from "bernie-analytics";
import { EGClickStreamTrackerProps } from "./typings";
import {
  InteractionEvent,
  buildFlexRfrrId,
  EGClickStreamTrackingInfo,
  logTrackEventToSplunk,
} from "../EGClickStreamAnalyticsUtils";
import { inject } from "mobx-react";

/**
 * Generic implementation for Click Tracking for the Flex platform.
 * The 'moduleName' prop is required.
 *
 * Example use case:
 *    const TrackedButton = FlexClickTrackerProps(UitkPrimaryButton);
 *    <TrackedButton moduleName="hotels" rfrr="seeAllButton" />
 *
 * This will generate a rfrr ID of <rfrrNamespace>.hotels.seeAllButton.click
 *
 * NOTE: rfrrNamespace is defined in the controller (e.g. TG.LP)
 *
 */
const EGClickTrackerFunc = (WrappedComponent: any) => {
  @inject("context")
  class EGClickTracker extends React.Component<EGClickStreamTrackerProps, {}> {
    public render() {
      const TrackingComponent = ClickTracker(WrappedComponent);
      const { moduleName, rfrr, linkName, onClick, context, uisPrimeMicroMessages, ...allOtherProps } = this.props;

      const contextLob = context?.searchContext?.lob;
      const rfrrId = rfrr && contextLob ? `${contextLob}.${rfrr}` : rfrr;

      const analyticsInfo: EGClickStreamTrackingInfo = {
        moduleName,
        rfrr: rfrrId.replace(/ /g, "_"),
        linkName,
        action: InteractionEvent.SELECTED,
      };

      const logToSplunk = () => {
        if (typeof onClick === "function") {
          onClick();
        }

        logTrackEventToSplunk(analyticsInfo);
      };

      return (
        <TrackingComponent
          rfrr={buildFlexRfrrId(analyticsInfo)}
          linkName={linkName || "RFRR Action Link"}
          onClick={logToSplunk}
          trackImmediate
          uisPrimeMicroMessages={uisPrimeMicroMessages}
          {...allOtherProps}
        />
      );
    }
  }

  return EGClickTracker;
};

export { EGClickTrackerFunc as EGClickStreamTracker };

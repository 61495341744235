import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";
import * as React from "react";
import { ImpressionEvent } from "components/utility/analytics/EGClickStreamAnalyticsUtils";
import { inject } from "mobx-react";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

export interface TrackedVisibilityProps {
  moduleName: string;
  context?: ExtendedContextStore;
  linkName?: string;
  rfrrId: string;
  children?: React.ReactNode;
}

export const TrackedVisibility = inject("context")((props: TrackedVisibilityProps) => {
  const { children, rfrrId, linkName, moduleName, context } = props;
  const contextLob = context?.searchContext?.lob;
  const rfrr = rfrrId && contextLob ? `${contextLob}.${rfrrId}` : rfrrId;

  return (
    <IntersectionTracker
      referrerId={`${rfrr.replace(/ /g, "_")}.${ImpressionEvent.PRESENTED}`}
      linkName={linkName || `${moduleName} has been viewed`}
    >
      {children}
    </IntersectionTracker>
  );
});
